import React from "react";
import Link from "gatsby-link";

import { Jumbotron, Grid, Row, Col, Image, Button } from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import Header from "../../components/header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout";
import JobHeader from "../../components/job-header.js";
const SpreadCoPage = () => (
  <Layout>
    <JobHeader
      image="/img/spread-co/iphone-screens-v2.jpg"
      title="Spread Co"
      subtitle="A financial spread betting company"
      text="Web Design / Design Lead / Prototyping / Framer"
    />

    <Row className="show-grid side-padding intro">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>My Role</h2>
        <p>
          <em>
            Being the only designer at Spread Co my I covered a wide range of
            projects. From rebranding the company, to redeisgning the platform.
            I also redesigned their website and created all of their print
            advertisements. I learnt a huge amount at Spread Co and it has given
            me some core skills that I use every day in my current role.{" "}
          </em>
        </p>
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <Image src="/img/spread-co/iphone-screens-v2.jpg" />
      </Col>
    </Row>

    <Row className="show-grid side-padding ptb40">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>iOS App</h2>
        <p>
          I completely redesign their app from scratch. I created prototypes to
          ensure the userflow was right, often passing it around the office of
          experienced traders to check they were happy with it. I then presented
          this to the CEO who signed it off, allowing me to work with the
          developers to put the app in to production.
        </p>
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={4}>
        <Image src="/img/spread-co/chart-green2.png" />
      </Col>
      <Col xs={12} md={4}>
        <Image src="/img/spread-co/eco-cal.png" />
      </Col>
      <Col xs={12} md={4}>
        <Image src="/img/spread-co/home.png" />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={4}>
        <Image src="/img/spread-co/market-info.png" />
      </Col>
      <Col xs={12} md={4}>
        <Image src="/img/spread-co/markets2.png" />
      </Col>
      <Col xs={12} md={4}>
        <Image src="/img/spread-co/news.png" />
      </Col>
    </Row>

    <Row className="show-grid side-padding ptb40">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>Website Design</h2>
        <p>
          One of my jobs at Spread Co was redesigning the website. I used google
          analytics data to inform my designs, making sure that conversion was
          the main point of focus for the desing. I then worked closely with
          outsourced developers to get the site built.{" "}
        </p>
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12}>
        <Image src="/img/spread-co/spreadco-homepage.jpg" />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={6}>
        <Image src="/img/spread-co/website-blog.jpg" />
      </Col>
      <Col xs={12} md={6}>
        <Image src="/img/spread-co/cfd-site.jpg" />
      </Col>
    </Row>

    <Row className="show-grid side-padding ptb40">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>Print Advertisements</h2>
        <p>
          Another part of my role at Spread Co was creating the print
          advertisements that would appear in Newspapers and magazines. For this
          I worked closely with the Marketing team, often having to react
          quickly to market news to create reactive pieces.
        </p>
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={6}>
        <Image src="/img/spread-co/pun_campaign_bear_longblue.jpg" />
      </Col>
      <Col xs={12} md={6}>
        <Image src="/img/spread-co/pun_campaign_battle_longblue.jpg" />
      </Col>
    </Row>

    <Row className="show-grid">
      <Col xs={12} md={6}>
        <Image src="/img/spread-co/pun_campaign_template.jpg" />
      </Col>
      <Col xs={12} md={6}>
        <Image src="/img/spread-co/pun_pic_template-margin.jpg" />
      </Col>
    </Row>

    <UpNext
      image1="/img/marionnaud-card2.jpg"
      title1="Marionnaud"
      text1="A European Health & Beauty brand"
      url1="/marionnaud/"
      image2="/img/perfumeshop-card.jpg"
      title2="The Perfume Shop"
      text2="A fragrance specialist based in the UK"
      url2="/perfume-shop/"
      image3="/img/superdrug-mobile-card.jpg"
      title3="Superdrug Mobile"
      text3="Creating a mobile brand from scratch"
      url3="/superdrug-mobile/"
    />
  </Layout>
);

export default SpreadCoPage;
